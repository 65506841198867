.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;;
  }
  
  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #f8f8f8;
  }
  
  .star-rating {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .star {
    font-size: 24px;
    color: #ddd;  /* Default color for empty stars */
    position: relative;
  }
  
  .star.filled {
    color: gold;
  }
  
  .star.half-filled {
    color: gold;
  }
  
  .star.half-filled::before {
    content: '\2605';  /* Unicode star character */
    position: absolute;
    color: gold;
    width: 50%;
    overflow: hidden;
  }
  