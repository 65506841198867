* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.app-container {
  display: flex;
  height: 100vh;
}

/* Left Column */
.left-column {
  width: 20%;
  background-color: #e9f8f8;
  color: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}

.logo-section {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #5bd4f8;
}

.menu {
  width: 100%;
}

.menu-item {
  padding: 20px;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #777474;
}

.menu-item.active {
  background-color: #8cdddd;
}

.menu-item:hover {
  background-color: #d5f0f0;
}

/* Right Column */
.right-column {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.row {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

h3 {
  font-size: 24px;
  color: #333;
}
