/* Styling for the individual card */
.card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    width: 23%; /* Four cards side by side */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 0 5px; /* Small spacing between the cards */
  }
  
  .card h1 {
    margin-bottom: 10px;
    font-size: 48px;
  }
  
  .card h4 {
    color: #666;
  }
  