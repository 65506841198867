.card-container {
  display: flex;
  justify-content: space-between;
  height: 100%; /* Full height of TopRow */
  width: 100%; /* Full width of TopRow */
}

.card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 23%; /* Adjust as needed to fit 4 cards in the container */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  
}

.card h4 {
  margin-bottom: 10px;
  font-size: 18px;
}

.card p {
  font-size: 14px;
  color: #666;
}