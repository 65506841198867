.rewards-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin: 10px;
  }
  
  .reward-image {
    width: 20%; /* Since you want equal sizes, 20% width for each of 5 images */
    height: auto;
    max-width: 100px; /* Add a max-width to prevent too large images */
    border: 2px solid #0f0f0f;
    border-radius: 5px;
    margin: 1px;
  }
  