.tabbed-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .tabs {
    display: flex;
    justify-content: space-between;
    background-color: #f1f1f1;
    padding: 10px;
  }
  
  .tabs button {
    flex-grow: 1;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s ease;
  }
  
  .tabs button.active {
    background-color: #e0e0e0;
    font-weight: bold;
  }
  
  .tabs button:not(.active):hover {
    background-color: #f5f5f5;
  }
  
  .tab-content {
    flex-grow: 1;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-top: 10px;
    overflow-y: auto;
  }
  
  .list-group {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .list-group-item {
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: background-color 0.2s ease;
  }
  
  /* Campaigns List */
  .campaigns-list .list-group-item {
    background-color: #7df8f2; /* Yellow background for Campaigns */
  }
  
  .campaigns-list .list-group-item:hover {
    background-color: #47d1f3; /* Darker yellow on hover */
  }
  
  /* Messages List */
  .messages-list .list-group-item {
    background-color: #a5eeaf; /* Light green background for Messages */
  }
  
  .messages-list .list-group-item:hover {
    background-color: #53f333; /* Darker green on hover */
  }
  
  /* Bids List */
  .bids-list .list-group-item {
    background-color: #abdbf1; /* Light blue background for Bids */
  }
  
  .bids-list .list-group-item:hover {
    background-color: #4eb7f0; /* Darker blue on hover */
  }
  