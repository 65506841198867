.image-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110px;
    height: 150px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 5px; /* Ensure spacing around each card */
    
  }
  
  .card-image {
    width: 80px; /* Adjusted size */
    height: 80px; /* Adjusted size */
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .card-name {
    font-size: 12px;
    color: #333;
  }
  